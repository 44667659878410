.warpper {
  max-width: 960px;
  margin: 0 auto;
}

.error-code {
  padding: 40px;
  background: #eaeaea;
  font-size: 20px;
}

button {
  margin: 20px 0;
}
