@charset "UTF-8";
/* ----------
  共通
---------- */
.Logo {
  min-height: 30px;
  margin-left: 20px;
  width: 172px;
  margin-right: 10px;
}

.terms-body {
  margin: 0 auto;
  padding-top: 2rem;
  padding-right: 1rem;
  padding-bottom: 2rem;
  padding-left: 1rem;
  max-width: 960px;
}

.terms-header {
  display: flex;
  margin-bottom: 2rem;
  justify-content: flex-start;
}

.terms-header-button {
  display: inline-block;
  padding: 12px;
  text-decoration: none;
  border: 1px solid #444f69;
  border-right: none;
  background: #fff;
  color: #444f69;
  width: 12rem;
  text-align: center;
  &:last-of-type {
    border-right: 1px solid #444f69;
  }
}

.md-heading1 {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.terms-text {
  padding: 2rem;
  background-color: #fff;
  border: #444f694d 1px solid;
}

.terms-header-button-active {
  background: #444f69;
  color: #fff;
}

@media screen and (max-width: 480px) {
  .terms-text.markdown-body {
    font-size: 75%;
  }

  .terms-header-button {
    font-size: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.card {
  padding: 2rem;
  background-color: #fff;
  border: #444f694d 1px solid;
  box-shadow: 1px 1px 6px 1px #e0dcdc;
}
@media screen and (max-width: 480px) {
  .card {
    padding: 1rem;
    background-color: #fff;
    border: #444f694d 1px solid;
    box-shadow: 1px 1px 6px 1px #e0dcdc;
  }
}
